<template>
    <div>
        <div class="card-titulo d-flex justify-content-between align-items-center">
            <button type="button" class="btn btn-sm" aria-label="Left Align"  @click.prevent.stop="$router.go(-1)">
                <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>
            <h1>CALENDÁRIO</h1>
            <i></i>
        </div>
        <div class="cards card-horario mb-2">
            <div>
                <h2>{{matricula.turma.turm_identificacao}}</h2>
                <h1 v-if="infoEscola.esc_tipo === 1">{{matricula.turma.serie.ser_descricao}}</h1>
                <h1>{{matricula.turma.curso.cur_nome}}</h1>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <small>Matrícula</small>
                        <span>{{matricula.matri_matricula}}</span>
                    </div>
                </div>
            </div>
        </div> 

        <div class="tab-content my-0 py-0">
            <div class="row grid-titulo d-flex">
                <div style="flex-grow: 1;">
                    <span>Mês/Dia</span>
                </div>
                <template v-for="dia in 31">
                    <div class="text-center" style="width: 33px" :key="dia">
                        <span>{{dia}}</span>
                    </div>
                </template>
            </div>
        </div>
        <template v-for="(mes,idx) in meses">
            <div class="tab-content my-0 py-0" :key="idx">
                <div class="row grid-titulo d-flex">
                    <div style="flex-grow: 1;">
                        <span>{{mes.text}}</span>
                    </div>
                    <template v-for="dia in 31">
                        <div :id="'dia_'+mes.value+'_'+dia" class="text-center grid-titulo-dias" style="width: 33px" :key="dia" 
                            :class="dia > moment(`${$route.params.ano_letivo}-${mes.value}-1`, 'YYYY/MM/DD').locale('pt-BR').endOf('month').date() ? 'bg-secondary' : 'bg-light'">
                            
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <div class="tab-content my-0 py-0 mt-3">
            <div class="row grid-titulo d-flex">
                <div class="text-center feriado" style="width: 33px;">
                    <span>FER</span>
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>FERIADO</span>
                </div>
            </div>
            <div class="row grid-titulo d-flex">
                <div class="text-center domingo" style="width: 33px;">
                    <span>D</span>
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>DOMINGO</span>
                </div>
            </div>
            <div class="row grid-titulo d-flex">
                <div class="text-center sabado" style="width: 33px;">
                    <span>S</span>
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>SÁBADO</span>
                </div>
            </div>
            <div class="row grid-titulo d-flex">
                <div class="text-center ferias" style="width: 33px;">
                    <span>R/F</span>
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>RECESSO/FÉRIAS</span>
                </div>
            </div>
            <div class="row grid-titulo d-flex">
                <div class="text-center bimestre" style="width: 33px;">
                    <span class="fa fa-lg fa-long-arrow-right"></span>
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>INÍCIO BIMESTRE/SEMESTRE</span>
                </div>
            </div>
            <div class="row grid-titulo d-flex">
                <div class="text-center bimestre" style="width: 33px;">
                    <span class="fa fa-lg fa-long-arrow-left"></span>
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>FIM BIMESTRE/SEMESTRE</span>
                </div>
            </div>
            <div class="row grid-titulo d-flex">
                <div class="text-center dia-letivo" style="width: 33px;">
                    
                </div>
                <div style="width: 200px; background-color: var(--gray-100)">
                    <span>DIA LETIVO</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
    Name: 'PortalCalendario',
    components: {

    },
    data () {
        return {
            calendario: [],
            meses: [
                {value: 1, text: 'Janeiro'},
                {value: 2, text: 'Fevereiro'},
                {value: 3, text: 'Março'},
                {value: 4, text: 'Abril'},
                {value: 5, text: 'Maio'},
                {value: 6, text: 'Junho'},
                {value: 7, text: 'Julho'},
                {value: 8, text: 'Agosto'},
                {value: 9, text: 'Setembro'},
                {value: 10, text: 'Outubro'},
                {value: 11, text: 'Novembro'},
                {value: 12, text: 'Dezembro'}
            ]
        }
    },
    methods: {
        async getCalendario () {
            await axios.get('/calendario',
            {
                params: {
                    entidade: this.$route.params.entidade,
                    escola: this.$route.params.escola,
                    anoletivo: this.$route.params.ano_letivo
                }
            })
            .then(response => {
                this.calendario = response.data.data;  
                this.onColorirDia();          
            })
        },
        onColorirDia (){
            
            this.calendario.forEach(item => {
                let mes =  moment(item.calen_dia).locale('pt-BR').month()+1;
                let dia =  moment(item.calen_dia).locale('pt-BR').date();
                let ele = document.getElementById('dia_'+mes+'_'+dia);

                if ((item.calen_legenda_1 === 'DOMINGO')||(item.calen_legenda_2 === 'DOMINGO')||(item.calen_legenda_3 === 'DOMINGO')){
                    ele.classList.add('domingo');
                    ele.innerHTML = '<span>D</span>';
                } else
                if ((item.calen_legenda_1 === 'SÁBADO')||(item.calen_legenda_2 === 'SÁBADO')||(item.calen_legenda_3 === 'SÁBADO')){
                    ele.classList.add('sabado');
                    ele.innerHTML = '<span>S</span>';
                } else
                if ((item.calen_legenda_1 === 'FERIADO NACIONAL')||(item.calen_legenda_2 === 'FERIADO NACIONAL')||(item.calen_legenda_3 === 'FERIADO NACIONAL')||
                    (item.calen_legenda_1 === 'FERIADO MUNICIPAL')||(item.calen_legenda_2 === 'FERIADO MUNICIPAL')||(item.calen_legenda_3 === 'FERIADO MUNICIPAL')||
                    (item.calen_legenda_1 === 'FERIADO ESTADUAL')||(item.calen_legenda_2 === 'FERIADO ESTADUAL')||(item.calen_legenda_3 === 'FERIADO ESTADUAL')
                ){
                    ele.classList.add('feriado');
                    ele.innerHTML = '<span>FER</span>';
                } else
                if ((item.calen_legenda_1 === 'INICIO DE RECESSO')||(item.calen_legenda_2 === 'INICIO DE RECESSO')||(item.calen_legenda_3 === 'INICIO DE RECESSO')||
                    (item.calen_legenda_1 === 'FIM DE RECESSO')||(item.calen_legenda_2 === 'FIM DE RECESSO')||(item.calen_legenda_3 === 'FIM DE RECESSO')||
                    (item.calen_legenda_1 === 'RECESSO')||(item.calen_legenda_2 === 'RECESSO')||(item.calen_legenda_3 === 'RECESSO')
                
                ){
                    ele.classList.add('recesso');
                    ele.innerHTML = '<span>R/F</span>';
                } else
                if ((item.calen_legenda_1 === 'FÉRIAS')||(item.calen_legenda_2 === 'FÉRIAS')||(item.calen_legenda_3 === 'FÉRIAS')){
                    ele.classList.add('ferias');
                    ele.innerHTML = '<span>R/F</span>';
                } else
                if ((item.calen_legenda_1 === 'INÍCIO 1º BIMESTRE')||(item.calen_legenda_2 === 'INÍCIO 1º BIMESTRE')||(item.calen_legenda_3 === 'INÍCIO 1º BIMESTRE')||
                    (item.calen_legenda_1 === 'INÍCIO 2º BIMESTRE')||(item.calen_legenda_2 === 'INÍCIO 2º BIMESTRE')||(item.calen_legenda_3 === 'INÍCIO 2º BIMESTRE')||
                    (item.calen_legenda_1 === 'INÍCIO 3º BIMESTRE')||(item.calen_legenda_2 === 'INÍCIO 3º BIMESTRE')||(item.calen_legenda_3 === 'INÍCIO 3º BIMESTRE')||
                    (item.calen_legenda_1 === 'INÍCIO 4º BIMESTRE')||(item.calen_legenda_2 === 'INÍCIO 4º BIMESTRE')||(item.calen_legenda_3 === 'INÍCIO 4º BIMESTRE')              
                ){
                    ele.classList.add('bimestre');
                    ele.innerHTML = '<span class="fa fa-lg fa-long-arrow-right"></span>';
                } else
                if ((item.calen_legenda_1 === 'FIM 1º BIMESTRE')||(item.calen_legenda_2 === 'FIM 1º BIMESTRE')||(item.calen_legenda_3 === 'FIM 1º BIMESTRE')||
                    (item.calen_legenda_1 === 'FIM 2º BIMESTRE')||(item.calen_legenda_2 === 'FIM 2º BIMESTRE')||(item.calen_legenda_3 === 'FIM 2º BIMESTRE')||
                    (item.calen_legenda_1 === 'FIM 3º BIMESTRE')||(item.calen_legenda_2 === 'FIM 3º BIMESTRE')||(item.calen_legenda_3 === 'FIM 3º BIMESTRE')||
                    (item.calen_legenda_1 === 'FIM 4º BIMESTRE')||(item.calen_legenda_2 === 'FIM 4º BIMESTRE')||(item.calen_legenda_3 === 'FIM 4º BIMESTRE')                
                ){
                    ele.classList.add('bimestre');
                    ele.innerHTML = '<span class="fa fa-lg fa-long-arrow-left"></span>';
                } else
                if ((item.calen_legenda_1 === 'INICIO DE RECUPERAÇÃO')||(item.calen_legenda_2 === 'INICIO DE RECUPERAÇÃO')||(item.calen_legenda_3 === 'INICIO DE RECUPERAÇÃO')||
                    (item.calen_legenda_1 === 'FIM DE RECUPERAÇÃO')||(item.calen_legenda_2 === 'FIM DE RECUPERAÇÃO')||(item.calen_legenda_3 === 'FIM DE RECUPERAÇÃO')||
                    (item.calen_legenda_1 === 'RECUPERAÇÃO')||(item.calen_legenda_2 === 'RECUPERAÇÃO')||(item.calen_legenda_3 === 'RECUPERAÇÃO')
                ){
                    ele.classList.add('recuperacao');
                    ele.innerHTML = '<span>REC</span>';
                } else
                if ((item.calen_legenda_1 === 'DIA LETIVO')||(item.calen_legenda_2 === 'DIA LETIVO')||(item.calen_legenda_3 === 'DIA LETIVO')){
                    ele.classList.add('dia-letivo');
                    ele.innerHTML = '<span></span>';
                }
                
            });
            
        }

    },
    mounted () {
        this.getCalendario();    
    },
    computed: {
        ...mapState('Portal',['matriculas','infoEscola']),
        matricula () {
            return this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma)[0];
        },
    }
}
</script>

<style scoped>
.grid-titulo > div > span,
.grid-dados > div > span {
    font-size: 12px;
}

.domingo {
    background-color: var(--red)!important;
}

.sabado {
    background-color: #FA9897!important;
}

.feriado {
    background-color: var(--purple)!important;
}

.recesso {
    background-color: var(--gray-400)!important;
}

.ferias {
    background-color: var(--gray-600)!important;
}

.recuperacao {
    background-color: var(--blue)!important;
}

.bimestre {
    background-color: var(--blue)!important;
}

.dia-letivo {
    background-color: #B1E0EE!important;
}

.domingo > span,
.sabado > span,
.feriado > span,
.recesso > span,
.ferias > span,
.recuperacao > span,
.bimestre > span,
.dia-letivo > span {
    color: #FFFFFF!important;
}

</style>
